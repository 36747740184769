








































































import {Component, Mixins, Ref, Vue, Watch} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import Promo from "@/models/Promo"
import PromoService from "@/services/PromoService"
import LangModule from "@/store/LangModule"
import Catalogue from "@/models/Catalogue"
import CatalogueService from "@/services/CatalogueService"
import StringTool from "@/services/tool/StringTool"
import FilePickerComponent from "@/components/FilePickerComponent.vue"
import PaginationMixin from "@/mixins/PaginationMixin";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";

@Component({components: {FilePickerComponent}})
export default class CataloguesView extends Mixins(PaginationMixin) {
    lang: any = getModule(LangModule).lang
    @Ref() readonly form!: HTMLFormElement
    dialog: boolean = false
    catalogue: Catalogue = new Catalogue()
    catalogues: Catalogue[] = []

    catalogues2: MultipleItem<Catalogue> = { items: [], totalItems: 0 }

    promos: Promo[] = []
    promo: Promo = new Promo()
    page: number = 1
    pageCount: number = 0
    itemsPerPage: number = 10
    loading: boolean = false
    search: string = ""
    active: boolean = false
    headers = [
        {align: 'center', text: 'ID', value: "id", width: "auto"},
        {align: 'center', text: this.lang.name, value: "name", width: "auto"},
    ]
    file: File | null = null
    rulesFile = [(v: any) => v ? true : this.lang.documentRequired]

    rules = {
        required: (input: string) => (input ? true : this.lang.requiredField),
        email: (input: string) => StringTool.validateEmail(input) ? true : this.lang.emailNoValid,
        postalCode: (input: string) => StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
    }

    created() {
        this.refresh()
    }

    async refresh() {
        await Handler.getItems(this, this.catalogues2, () =>
          CatalogueService.getCatalogues(this.page - 1, this.size, this.search, this.active)
        )
        this.setPageCount(this.catalogues2.totalItems!!)
    }

    rowClick(catalogue: Catalogue) {
        this.$router.push({path: "/catalogues/" + catalogue.id})
    }

    async createCatalogue() {
        if (this.form.validate()) {
            await CatalogueService.postCatalogue(this, this.catalogue, this.file!)
        }
        this.dialog = false
        this.catalogue = new Catalogue()
        await this.refresh()
    }

    openCreateDialog() {
        if (this.form) this.form.resetValidation()
        this.dialog = true
        this.promo = new Promo()
        this.file = null
    }

    @Watch("active")
    onFiltersChanged() {
        this.refresh()
    }

}
